import {doc, getDoc, getFirestore, serverTimestamp, setDoc} from "firebase/firestore";

export async function writeDBPreferences(user, prefs) {
  const docRef =
    preferencesDocumentReference(user)

  const snapshot = await getDoc(docRef)
  const data = {
    ...(snapshot.exists() ? snapshot.data() : {createdAt: serverTimestamp()}),
    ...prefs,
    uid: user.uid,
    updatedAt: serverTimestamp()
  }
  await setDoc(docRef, data)
}

export async function readDBPreferences(user) {
  const ref = preferencesDocumentReference(user);
  const prefs = await getDoc(ref)
  return prefs.exists() ? prefs.data() : {uid: user.uid}
}

function preferencesDocumentReference(user) {
  const firestore = getFirestore(window.firebaseApp)
  return doc(firestore, "preferences", user.providerId + user.uid)
}