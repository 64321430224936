import {collection, doc, getDoc, getDocs, getFirestore, serverTimestamp, setDoc} from "firebase/firestore";

export async function readAllTags() {

  const firestore = getFirestore(window.firebaseApp)
  const querySnapshot =
    await getDocs(collection(firestore, "tags"));

  const docs = []
  querySnapshot.forEach((doc) => {
    docs.push(doc.data())
  });
  return docs;
}

export async function writeTag(tag, songIDs) {

  const firestore = getFirestore(window.firebaseApp)
  const docRef = doc(firestore, "tags", tag)

  const snapshot = await getDoc(docRef)
  const data = {

    ...(snapshot.exists()
      ? snapshot.data()
      : {
        createdAt: serverTimestamp(),
        createdBy: window.firebaseUser.uid
      }),
    name: tag,
    songIDs,
    updatedBy: window.firebaseUser.uid,
    updatedAt: serverTimestamp()
  }
  await setDoc(docRef, data)
}
