import {initializeApp} from "firebase/app";
// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  connectAuthEmulator,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  getFirestore,
  connectFirestoreEmulator
} from 'firebase/firestore'
import {readDBPreferences, writeDBPreferences} from "./db.fb.preferences.mjs";
import {readAllTags, writeTag} from "./db.fb.tags.mjs";
import {signInWithPopup} from "@firebase/auth";

function initializeServices() {



  const useEmulator = window.location.hostname === 'localhost';

  const firebaseConfig = document.location.hostname == 'staging.gotomyhead.site' ? {
    apiKey: "AIzaSyDpe6BFFhAg_GmwaCD04K5pMVH3W0Lhcgo",
    authDomain: "go-to-my-head.firebaseapp.com",
    databaseURL: "https://go-to-my-head-default-rtdb.firebaseio.com",
    projectId: "go-to-my-head",
    storageBucket: "go-to-my-head.firebasestorage.app",
    messagingSenderId: "414557686423",
    appId: "1:414557686423:web:26ee317231a003b247b7d2"
  } : {
    apiKey: "AIzaSyCJpXkN7d9UursADdGRXNxNZNAiGm9v7Cs",
    authDomain: useEmulator ? "localhost:9099" : "go-to-my-head.firebaseapp.com",
    projectId: "go-to-my-head",
    appId: "1:414557686423:web:b3e69c507031909247b7d2",
    databaseURL: useEmulator ? 'http://localhost:8080' : "https://go-to-my-head-default-rtdb.firebaseio.com/",
  };

  const app = initializeApp(firebaseConfig)

  if (useEmulator) {
    const auth = getAuth(app);
    connectAuthEmulator(auth, "http://localhost:9099")

    const db = getFirestore(app);
    connectFirestoreEmulator(db, "localhost", 8080)
  } else {
    initializeFirestore(app,
      {localCache:
          persistentLocalCache({tabManager: persistentMultipleTabManager()})
      });
  }

  return app
}

window.firebaseApp = initializeServices()
const auth = getAuth(window.firebaseApp);

const signInButton = document.getElementById(
  'sign-in',
)

signInButton.addEventListener('click', toggleSignIn, false);

function toggleSignIn() {
  if (!auth.currentUser) {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/plus.login');
    signInWithPopup(auth, provider);
  } else {
    signOut(auth);
  }
  signInButton.disabled = true;
}


// Result from Redirect auth flow.
getRedirectResult(auth)
  .then(function (result) {
    if (!result) return;
    const credential = GoogleAuthProvider.credentialFromResult(result);
    if (credential) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const token = credential?.accessToken;
    }
    // The signed-in user info.
    const user = result.user;

  })
  .catch(function (error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    const credential = error.credential;
    if (errorCode === 'auth/account-exists-with-different-credential') {
      alert(
        'You have already signed up with a different auth provider for that email.',
      );
      // If you are using multiple auth providers on your app you should handle linking
      // the user's accounts here.
    } else {
      console.error(error);
    }
  });

onAuthStateChanged(auth, function (user) {
  if (user) {
    // User is signed in.
    const displayName = user.displayName;
    const email = user.email;
    const emailVerified = user.emailVerified;
    const photoURL = user.photoURL;
    const isAnonymous = user.isAnonymous;
    const uid = user.uid;
    const providerData = user.providerData;
    console.log({displayName, email, emailVerified, photoURL, isAnonymous, uid, providerData})

    signInButton.textContent = 'Sign out';
  } else {
    signInButton.textContent = '🅶 Sign in';
  }
  signInButton.disabled = false;
});


let count = 1
onAuthStateChanged(auth, async (user) => {
  window.firebaseUser = user
  if (user) {
    window.readAllTaggings = readAllTags
    window.writeSongTaggings = writeTag
    window.writeDBPreferences = writeDBPreferences.bind(null, user)
    window.readDBPreferences = readDBPreferences.bind(null, user)
  } else {
    window.readAllTaggings = null
    window.writeSongTaggings = null
    window.writeDBPreferences = null
    window.readDBPreferences = null
  }

  document.dispatchEvent(new CustomEvent('authStateChanged', {detail: {count, user}}))
  window.authStateChanged = true
  count += 1
})
